const table = {
  data() {
    return {
      columns: [
        {
          width: 230,
          prop: 'name',
          label: '模板名称',
          href: true,
          handle: this.view
        },
        {
          width: 230,
          prop: 'deviceTypeName',
          label: '设备类型'
        },
        {
          width: 120,
          prop: 'deviceNumber',
          label: '当前设备数量',
          align: 'center'
        }
      ],
      operations: {
        handles: []
      }
    };
  }
};
export { table };
