<template>
  <!-- TODO redo -->
  <div class="template">
    <list-container placeholder="模板名称/设备类型" :searchText.sync="searchText" :pagination="pagination" @fetch="loadData">
      <template v-slot:grid>
        <grid :Gridtype="4" :GridData="data" @intoGrid="view" />
      </template>
      <template v-slot:table>
        <base-table :data="data" :columns="columns" :operations="operations" :multi="false" />
      </template>
    </list-container>
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import grid from '@/components/grid/index.vue';
import baseTable from '@/components/table/base-table/base-table';
import { table } from './mixin';
import { TABLE } from '/app.config';
export default {
  name: 'device_template',
  mixins: [table],
  data() {
    return {
      drawer: false,
      drawerData: [],
      data: [],
      searchText: '',
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    // 加载数据
    async loadData(param = {}) {
      const params = {
        keyword: this.searchText,
        currentPage: param.currentPage || this.pagination.currentPage,
        currentPageSize: param.currentPageSize || this.pagination.pageSize,
        isVirtual: true
      };

      const { items, total, currentPage, currentPageSize } = await this.$apis.deviceTemplate.list(params);
      this.data = items;

      this.pagination.total = total;
      this.pagination.pageSize = currentPageSize;
      this.pagination.currentPage = currentPage;
    },
    // 查看详情
    view({ templateId }) {
      this.$router.push({
        name: 'virtualTemplateDetail',
        params: {
          id: templateId
        }
      });
    }
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  components: {
    listContainer,
    grid,
    baseTable
  }
};
</script>

<style scoped lang="scss">
.template {
  height: 100%;
}
.list-container > .main {
  height: 500px !important;
}
/deep/ .header > .addOrdel {
  display: none;
}
</style>
